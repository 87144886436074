import wordBank from "./wordle-bank.txt";
import answerBank from "./answer-bank.txt";

export const boardDefault = [
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
];

export const answersView = [
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
];

// we make it async because we need to fetch
export const generateWordSet = async () => {
  let wordSet;
  await fetch(wordBank)
    .then((response) => response.text())
    .then((result) => {
      const wordArr = result.split("\n");
      wordSet = new Set(wordArr);
    });
  return { wordSet };
};

export const generateAnswer = async () => {
  let correctWord;
  await fetch(answerBank)
    .then((response) => response.text())
    .then((result) => {
      const ansArr = result.split("\n");
      correctWord = ansArr[Math.floor(Math.random() * ansArr.length)];
    });
  return correctWord;
};
