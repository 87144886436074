import React, { useEffect } from "react";
import "./App.css";
import Board from "./components/Board";
import Keyboard from "./components/Keyboard";
import introPanel from "./components/intro_panel";
import { createContext, useState } from "react";
import {
  boardDefault,
  generateWordSet,
  answersView,
  generateAnswer,
} from "./Words";

export const AppContext = createContext();

function App() {
  //const [, setPanelOpen] = useState(true);
  const [board, setBoard] = useState(boardDefault);
  const [answersViewBoard, setAnswerView] = useState(answersView);
  const [currAttempt, setCurAttempt] = useState({ attempt: 0, letterPos: 0 });
  const [wordSet, setWordSet] = useState(new Set());
  const [correctWord, setAnsState] = useState("");
  const [disabledLetters, setDisabledLetters] = useState(new Set());
  const [fullyCorrectLetters, setFullyCorrectLetters] = useState(new Set());
  const [almostCorrectLetters, setaAlmostCorrectLetters] = useState(new Set());

  //const closePanel = () => setPanelOpen(false);

  const correctSet = new Set();
  for (let i = 0; i < 5; i++) {
    correctSet.add(correctWord[i]);
  }

  useEffect(() => {
    generateWordSet().then((words) => {
      setWordSet(words.wordSet);
    });
    generateAnswer().then((res) => {
      setAnsState(res);
    });
  }, []);

  const onDelete = () => {
    if (currAttempt.letterPos === 0) return;
    const newBoard = [...board];
    newBoard[currAttempt.attempt][currAttempt.letterPos - 1] = "";
    setBoard(newBoard);
    setCurAttempt({ ...currAttempt, letterPos: currAttempt.letterPos - 1 });
  };

  const onEnter = () => {
    const newanswersViewBoard = [...answersViewBoard];
    if (currAttempt.letterPos !== 5) return;
    let currWord = "";
    // currAttempt.attempt the most current line  -> we need to set all the IDs in this case
    for (let i = 0; i < 5; i++) {
      // grab the most recent try
      currWord += board[currAttempt.attempt][i];
    }
    if (wordSet.has(currWord.toLowerCase())) {
      //console.log("disabled letters", disabledLetters);
      var setIDs = checkWord(currWord, correctWord, disabledLetters);
      setCurAttempt({ attempt: currAttempt.attempt + 1, letterPos: 0 });
      for (let i = 0; i < 5; i++) {
        newanswersViewBoard[currAttempt.attempt][i] = setIDs[i];
      }
      console.log(newanswersViewBoard);
      return setIDs;
    } else {
      alert("Word Not Found in Word Bank");
    }
    // This is where we can call the function to color the cells - this only fires if
    // the letter Pos ==5 and we should "check the word" against the right word
  };

  const checkWord = (attempt, correctWord) => {
    // Iterate through
    var classIdArr = [];
    var remainingLetters = correctWord;
    // Check if green letters (letter and index match)
    for (var i = 0; i < 5; i++) {
      // match
      if (attempt[i] === correctWord[i]) {
        remainingLetters = remainingLetters.replace(attempt[i], "");
        classIdArr[i] = "correct";
      } else {
        classIdArr[i] = "error";
      }
    }

    // There are no more green letters for this attempt so we'll just look for yellow ones
    for (var x = 0; x < 5; x++) {
      if (
        attempt[x] !== correctWord[i] &&
        remainingLetters.includes(attempt[x])
      ) {
        classIdArr[x] = "almost";
        remainingLetters = remainingLetters.replace(attempt[x], "");
      }
    }
    // This is the final array output

    // set the function for different keyboards
    for (let i = 0; i < 5; i++) {
      // Set the disabled array
      if (classIdArr[i] === "error" && !correctSet.has(attempt[i])) {
        setDisabledLetters(disabledLetters.add(attempt[i]));
      }
      // Set the correct keyboards
      if (classIdArr[i] === "correct") {
        setFullyCorrectLetters(fullyCorrectLetters.add(attempt[i]));
      }
      // Set the almost keyboard
      if (classIdArr[i] === "almost") {
        setaAlmostCorrectLetters(almostCorrectLetters.add(attempt[i]));
      }
    }
    return classIdArr;
  };

  const onSelectLetter = (keyVal) => {
    if (currAttempt.letterPos > 4) return;
    const newBoard = [...board];
    newBoard[currAttempt.attempt][currAttempt.letterPos] = keyVal;
    setBoard(newBoard);
    setCurAttempt({ ...currAttempt, letterPos: currAttempt.letterPos + 1 });
  };

  return (
    <div className="App">
      <nav>
        <h1>Wordle - Goon and Hippy Edition</h1>
      </nav>
      <AppContext.Provider
        value={{
          board,
          setBoard,
          currAttempt,
          setCurAttempt,
          onSelectLetter,
          onEnter,
          onDelete,
          correctWord,
          answersViewBoard,
          setAnswerView,
          disabledLetters,
          setDisabledLetters,
          fullyCorrectLetters,
          setFullyCorrectLetters,
          almostCorrectLetters,
          setaAlmostCorrectLetters,
        }}
      >
        <div className="game">
          <Board />
          <Keyboard />
        </div>
      </AppContext.Provider>
    </div>
  );
}

export default App;
