import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";

function Letter({ letterPos, attemptVal }) {
  const {
    board,
    currAttempt,
    answersViewBoard,
    disabledLetters,
    setDisabledLetters,
    fullyCorrectLetters,
    setFullyCorrectLetters,
  } = useContext(AppContext);
  const letter = board[attemptVal][letterPos];
  // Set the id of the
  const letter_id = answersViewBoard[attemptVal][letterPos];

  const letterState = letter_id;

  return (
    //id={letterState}
    <div className="letter" id={letterState}>
      {letter}
    </div>
  );
}

export default Letter;
